import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

export interface TextInputProps {
  className?: string;
  name: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
}

export const TextInput = (props: TextInputProps) => {
  const { className = "", label, name, placeholder, options = {} } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl
      isRequired={!!options?.required || !!options?.pattern}
      className={className}
      isInvalid={errors[name]}
    >
      <FormLabel fontWeight="medium" htmlFor={name}>
        {label}
      </FormLabel>
      <Input
        shadow="md"
        rounded="sm"
        py="4"
        px="6"
        _focus={{
          shadow: "lg",
        }}
        variant="unstyled"
        placeholder={placeholder}
        {...register(name, options)}
      />
      <FormErrorMessage color="red.300">
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
};
