import {
    Checkbox, FormControl,

    FormErrorMessage
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { validationMessages } from "../../../../lib/consts";

export interface AgreementCheckboxProps {
  className?: string;
}

export const AgreementCheckbox = (props: AgreementCheckboxProps) => {
  const { className = "" } = props;

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={errors.agreement}>
      <Controller
        name="agreement"
        control={control}
        rules={{
          required: {
            value: true,
            message: validationMessages.agreement,
          },
        }}
        render={({ field }) => {
          return (
            <Checkbox
              // {...register("agreement", {

              // })}
              colorScheme="yellow"
              onChange={field.onChange}
              isChecked={field.value}
              checked={field.value}
              onBlur={field.onBlur}
              iconSize="1rem"
              className="text-gray-400"
            >
              <div>
                <span>
                  Udeľujem súhlas na spracovanie osobných údajov. Viac{" "}
                </span>
                <Link to={"/"} className="text-primary">
                  Ochrana osobných údajov
                </Link>
              </div>
            </Checkbox>
          );
        }}
      />
      <FormErrorMessage color="red.300">
        {errors.agreement && errors?.agreement?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
