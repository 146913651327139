import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export const useHeroImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query useHeroImage {
      file(relativePath: { eq: "home/hero/hero.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return file.childImageSharp.fluid;
};
